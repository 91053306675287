import React from 'react';
import * as ReactDOMClient from 'react-dom/client'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import nProgress from 'nprogress'
import App from './App'
import '../node_modules/nprogress/nprogress.css'
import 'swiper/css'
import './styles/style.scss'

const appContainer = document.getElementById('root')
const reactRoot = ReactDOMClient.createRoot(appContainer)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})

nProgress.configure({ showSpinner: false })

reactRoot.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
)