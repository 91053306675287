import React from 'react'
import {
    MenuItem,
    Footer,
    FooterBigmenu,
    FooterBottom,
    FooterColumn,
    FooterMenu
} from 'kunstmuseum-components'

const FooterComponent = () => (
    <Footer>
        <FooterBigmenu>
            <MenuItem link="https://www.kunstmuseum.de/newsletter/">Newsletter</MenuItem>
            <MenuItem link="https://www.instagram.com/kunstmuseumwolfsburg/">Instagram</MenuItem>
            <MenuItem link="https://twitter.com/kunstmuseumwob">Twitter</MenuItem>
            <MenuItem link="https://www.facebook.com/KunstmuseumWolfsburg">Facebook</MenuItem>
            <MenuItem link="https://www.youtube.com/user/KunstmuseumWolfsburg">YouTube</MenuItem>
        </FooterBigmenu>
        <FooterBottom>
            <FooterColumn>
                <p>
                    Hollerplatz 1<br />
                    38440 Wolfsburg
                </p>
            </FooterColumn>
            <FooterColumn>
                <p>
                    +49 (0)5361 26690<br />
                    info@kunstmuseum.de
                </p>
            </FooterColumn>
            <FooterColumn>
                <FooterMenu>
                    <MenuItem link="https://www.kunstmuseum.de/impressum">Impressum</MenuItem>
                    <MenuItem link="https://www.kunstmuseum.de/datenschutz">Datenschutzerklärung</MenuItem>
                </FooterMenu>
            </FooterColumn>
        </FooterBottom>
    </Footer>
)

export default FooterComponent