import React, { useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import nProgress from 'nprogress'
import { Button } from 'kunstmuseum-components'
import { loadArtists, useStoredSessionState, LanguageContext, TranslationStringsContext } from './Data'
import ArtworksGrid from './components/ArtworksGrid'

export default function ListView() {
    const [page, setPage] = useStoredSessionState(1, 'lastListViewPage')
    const siteLangContext = useContext(LanguageContext)
    const translationStrings = useContext(TranslationStringsContext)
    const listViewRef = useRef()

    const { data, isLoading, isSuccess } = useQuery({
        queryKey: ['artworkList', page, siteLangContext],
        queryFn: () => loadArtists(page, siteLangContext)
    })

    if (isLoading) {
        nProgress.start()
    }

    if (isSuccess) {
        nProgress.done()

        if (listViewRef.current) {
            listViewRef.current.scrollTop = 0
        }
    }

    return (
        <div className="list-view" ref={listViewRef}>
            {data && data.map((artist) => <ArtistListItem itemData={artist} key={artist.id} />)}
            <div className="pagination">
                {page > 1 ? <Button isSmall onClick={() => setPage(page - 1)}>&laquo; {translationStrings.common.prev_page}</Button> : <span />}
                {translationStrings.common.page} {page}
                <Button isSmall onClick={() => setPage(page + 1)}>{translationStrings.common.next_page} &raquo;</Button>
            </div>
        </div>
    )
}

function ArtistListItem(props) {
    const { firstname, lastname, slug, artworks } = props.itemData

    return (
        <div className="artist-list-item">
            <h2><Link to={'/artist/' + slug + '/'}><span dangerouslySetInnerHTML={{ __html: firstname + ' ' +  lastname}} /></Link></h2>
            <ArtworksGrid artworks={artworks} />
        </div>
    )
}