import React, { useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Button } from 'kunstmuseum-components'
import { Close, EnterKey, Search } from 'kunstmuseum-icons'
import { isTouchDevice } from "./Helpers"
import { TranslationStringsContext } from './Data'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

SwiperCore.use([Navigation, Pagination])

export default function IntroOverlay(props) {
    const translationStrings = useContext(TranslationStringsContext)
    const touchIntro = isTouchDevice()

    return(
        <div className="intro-overlay">
            <div className="inner">
                <button className="close-dialog" onClick={() => props.setIntroSeenFunc(true)}>
                    <Close /> 
                    <span className="screen-reader-text">{translationStrings.common.close}</span>
                </button>
                <Swiper
                    navigation
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <h4>{translationStrings.space.intro_step1.title}</h4>
                        <p>
                            {touchIntro ? translationStrings.space.intro_step1.navigation_touch_text : translationStrings.space.intro_step1.navigation_keyboard_text}
                        </p>
                        <p>
                            {touchIntro ? translationStrings.space.intro_step1.drag_the_circle : translationStrings.space.intro_step1.move_the_mouse} {translationStrings.space.intro_step1.to_look_around}
                        </p>
                    </SwiperSlide>
                    <SwiperSlide>
                        <h4>{translationStrings.space.intro_step2.title}</h4>
                        <p>
                            {touchIntro 
                                ? translationStrings.space.intro_step2.select_artwork_touch 
                                : <>{translationStrings.space.intro_step2.select_artwork} <EnterKey /></>
                            } {translationStrings.space.intro_step2.select_artwork2}
                        </p>
                        <p>
                            {translationStrings.space.intro_step2.go_back} {touchIntro ? translationStrings.space.controls.tap : translationStrings.space.controls.click} {translationStrings.space.intro_step2.go_back2}
                        </p>
                        <Button onClick={() => props.setIntroSeenFunc(true)}>{translationStrings.space.intro_step2.finish_button}</Button>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}