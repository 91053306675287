import React, { useState, useEffect, useRef, useContext } from 'react'
import nProgress from 'nprogress'
import { loadArtistDetails, LanguageContext, useQueryParams } from './Data'
import ArtworksGrid from './components/ArtworksGrid'

export default function ArtistOverlay(props) {
    const [artistDetails, setArtistDetails] = useState(null)
    const siteLangContext = useContext(LanguageContext)
    const query = useQueryParams()
    const ArtistOverlayRef = useRef()

    useEffect(() => {
        const langQueryParam = query.get('lang')

        setArtistDetails(null)
        loadArtistDetails(props.artistId, props.artistSlug, setArtistDetails, langQueryParam ? langQueryParam : siteLangContext)
    }, [props.artistId, props.artistSlug])

    // Set the progress to done when the artwork details change.
    useEffect(() => {
        nProgress.done()
        ArtistOverlayRef.current.scrollTop = 0
        return
    }, [artistDetails])

    return (
        <div className="artist-overlay" role="dialog" ref={ArtistOverlayRef}>
            <h2 className="title">
                {artistDetails ? 
                    <span dangerouslySetInnerHTML={{ __html: artistDetails.firstname + ' ' +  artistDetails.lastname}} />
                : 
                    <div className="placeholder" />
                }
            </h2>
            {artistDetails ?
                <div className="about" dangerouslySetInnerHTML={{ __html: artistDetails.content.rendered }} />
            :
                <div className="placeholder" />
            }
            {artistDetails ?
                <ArtworksGrid artworks={artistDetails.artworks} />
            :
                <div className="placeholder" />
            }
        </div>
    )
}