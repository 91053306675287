import React, { useMemo } from 'react'
import apiFetch from '@wordpress/api-fetch'
import { addQueryArgs } from '@wordpress/url'
import nProgress from 'nprogress'
import { useState, useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { ApiBaseUrl } from './App'
import { setArtworks } from './Scene'
import langStringsDe from './languages/de.json'
import langStringsEn from './languages/en.json'

export const defaultLanguage = 'de'
export const LanguageContext = React.createContext(defaultLanguage)
export const TranslationStringsContext = React.createContext(langStringsDe)

export function getLanguageParamValue(language) {
    return language === defaultLanguage ? '' : language
}

export function getTranslatedItems(siteLang) {
    switch (siteLang) {
        case 'de':
            return langStringsDe
        case 'en':
            return langStringsEn
        default:
            return langStringsDe
    }
}

export function apiSetup() {
    apiFetch.use(apiFetch.createRootURLMiddleware(ApiBaseUrl))
}

export function getCollectionPublication(language) {
    return fetch(
        addQueryArgs(
            'https://shop.kunstmuseum.de/wp-json/wc/store/products/141',
            { _fields: 'permalink,price_html', lang: language }
        )
    ).then(response => response.json())
}

export function loadRandomArtworks(language) {
    nProgress.start()

    apiFetch({
        path: addQueryArgs('wp/v2/artwork', { orderby: 'rand', per_page: 30, _fields: 'id,slug,featured_media,featured_image,width,height', meta_key: 'display_in_3d', meta_value: '1', lang: getLanguageParamValue(language) }),
    })
        .then(nProgress.inc())
        .then((randomArtworks) => setArtworks(randomArtworks))
        .catch(() => {
            nProgress.done()
        })
}

export function loadArtists(selectedPage, language) {
    return apiFetch({
        path: addQueryArgs('wp/v2/artist', { orderby: 'lastname', order: 'asc', per_page: 10, page: selectedPage, lang: getLanguageParamValue(language) }),
    })
}

export function loadArtistList(setArtistList, language) {
    return apiFetch({
        path: addQueryArgs('wp/v2/artist', { orderby: 'lastname', order: 'asc', per_page: -1, _fields: 'id,slug,firstname,lastname', lang: getLanguageParamValue(language) }),
    })
}

export function loadArtworkDetails(artworkId, artworkSlug, setArtworkDetails, language) {
    nProgress.start()

    apiFetch({
        path: (!artworkSlug) ? addQueryArgs('wp/v2/artwork/' + artworkId, { lang: getLanguageParamValue(language) }) : addQueryArgs('wp/v2/artwork', { slug: artworkSlug, lang: getLanguageParamValue(language) })
    })
        .then(nProgress.inc())
        .then((artworkDetails) => !artworkSlug ? artworkDetails : artworkDetails[0])
        .then((artworkDetails) => {
            return { ...artworkDetails, views: artworkDetails.views ? artworkDetails.views.filter((item) => item.id !== artworkDetails.featured_media) : false }
        })
        .then((artworkDetails) => setArtworkDetails(artworkDetails))
        .catch(() => {
            nProgress.done()
        })
}

export function loadArtistDetails(artistId, artistSlug, setArtistDetails, language) {
    nProgress.start()

    apiFetch({
        path: (!artistSlug) ? addQueryArgs('wp/v2/artist/' + artistId) : addQueryArgs('wp/v2/artist', { slug: artistSlug, lang: getLanguageParamValue(language) })
    })
        .then(nProgress.inc())
        .then((artistDetails) => setArtistDetails((!artistSlug) ? artistDetails : artistDetails[0]))
        .catch(() => {
            nProgress.done()
        })
}

export function performSearch(term, setSearchResults, language) {
    if (term.length === 0) return
    nProgress.start()

    apiFetch({
        path: addQueryArgs('wp/v2/search', { search: term, per_page: -1, lang: getLanguageParamValue(language) }),
    })
        .then(nProgress.inc())
        .then((searchResults) => setSearchResults(searchResults))
        .catch(() => {
            nProgress.done()
        })
}

export function pathMatches(path, pathname) {
    return !!matchPath(
        pathname ? pathname : window.location.pathname,
        path
    )
}

export function useStoredState(defaultValue, key) {
    const [value, setValue] = useState(() => {
        const storedValue = window.localStorage.getItem(key)
        return storedValue !== null
            ? JSON.parse(storedValue)
            : defaultValue
    })

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}

export function useStoredSessionState(defaultValue, key) {
    const [value, setValue] = useState(() => {
        const storedValue = window.sessionStorage.getItem(key)
        return storedValue !== null
            ? JSON.parse(storedValue)
            : defaultValue
    })

    useEffect(() => {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    return [value, setValue]
}

export function useQueryParams() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}