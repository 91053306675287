import { Link } from 'react-router-dom'

export default function ArtworksGrid(props) {
    const artworks = props.artworks

    return (
        <div className="artworks-grid">
            {artworks && artworks.map((artwork) => <ArtworkGridItem artwork={artwork} key={artwork.id} />)}
        </div>
    )
}

function ArtworkGridItem(props) {
    const { post_name, post_title, featured_image, year } = props.artwork

    return (
        <div className="artwork">
            <Link to={'/artwork/' + post_name + '/'}>
                {featured_image ? <img src={featured_image} alt={post_title} loading="lazy" /> : <div className="placeholder" />}
                <h4><em dangerouslySetInnerHTML={{__html: post_title}} />{year != '' && `, ${year}`}</h4>
            </Link>
        </div>
    )
}