/**
 * Returns if the current device is a touch device
 */
export function isTouchDevice() {
    if ('ontouchstart' in document.documentElement)
        return true

    return false
}

/**
 * Replace new lines in a string with break tags.
 * 
 * @param {string} string 
 */
export function newLineToBreakTag(string) {
    string = string.replace(/(?:\r\n|\r|\n)/g, '<br />');

    return string
}

/**
 * Replace vimeo or youtube links in a html string with an embed.
 * 
 * @param {string} html 
 */
export function videoLinkInHtmlToEmbed(html) {
    const vimeoPattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(.[^<]+)/g
    const youtubePattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.[^<]+)/g
    
    if(vimeoPattern.test(html)) {
        const replacement = '<iframe width="560" height="315" src="//player.vimeo.com/video/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'	           
        html = html.replace(vimeoPattern, replacement)
    }
    
    if(youtubePattern.test(html)) {
        const replacement = '<iframe width="560" height="315" src="//www.youtube-nocookie.com/embed/$1" frameborder="0" allowfullscreen></iframe>'
        html = html.replace(youtubePattern, replacement)
    }
    
    return html
}