import React, { useState, useEffect, useContext } from 'react'
import { BrowserRouter as Router, Route, useHistory, useLocation } from 'react-router-dom'
import useEventListener from '@use-it/event-listener'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import Scene from './Scene'
import { 
  apiSetup, 
  loadRandomArtworks, 
  pathMatches, 
  useStoredSessionState, 
  defaultLanguage, 
  LanguageContext, 
  TranslationStringsContext, 
  getTranslatedItems
} from './Data'
import { SiteHeader, MenuItem, FloatingNavigation } from 'kunstmuseum-components'
import { Randomize, Home, Search } from 'kunstmuseum-icons'
import logo from './media/logo_black.svg'
import flag_de from './media/flags/de.svg'
import flag_en from './media/flags/en.svg'
import WelcomePage from './WelcomePage'
import SearchOverlay from './SearchOverlay'
import DetailOverlay from './DetailOverlay'
import ArtistOverlay from './ArtistOverlay'
import ListView from './ListView'
import ArtistListView from './ArtistListView'

export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL

const ArtworkDetailRoute = (props) => <DetailOverlay artworkSlug={props.match.params.artworkSlug} />
const ArtistDetailRoute = (props) => <ArtistOverlay artistSlug={props.match.params.artistSlug} />

function Layout() {
  apiSetup()
  const currentBrowserLanguage = (navigator.language || navigator.userLanguage).match(/([a-z]){2}/)[0]
  const history = useHistory()
  const location = useLocation()
  const [view, setView] = useStoredSessionState('welcome', 'view')
  const [searchOpened, setSearchOpened] = useState(false)
  const [siteHeaderTransparent, setSiteHeaderTransparent] = useState(true)
  const [siteLang, setSiteLang] = useStoredSessionState(!isArtworkOrArtistPage() ? currentBrowserLanguage : 'de')
  useEventListener('keyup', ({ keyCode }) => {
    if (keyCode === 82 && !searchOpened && view === '3d') { // R
      loadRandomArtworks()
    }
  })

  useEffect(() => {
    const currentPath = location.pathname

    if (
      pathMatches('/artwork/:artworkSlug/', currentPath) ||
      pathMatches('/artist/:artistSlug/', currentPath) ||
      searchOpened ||
      view === 'welcome' ||
      view === 'list' ||
      view === 'artists'
    ) {
      setSiteHeaderTransparent(false)
      return
    }

    setSiteHeaderTransparent(true)
  }, [location, searchOpened, view])

  function isArtworkOrArtistPage() {
    return pathMatches('/artwork/:artworkSlug/') || pathMatches('/artist/:artistSlug/')
  }

  function returnToHome(event) {
    event.preventDefault()
    setView('welcome')
    history.push('/')
  }

  function returnToRoot(event) {
    event.preventDefault()
    history.push('/')
  }

  function toggleSearch(event) {
    event.preventDefault()
    setSearchOpened(!searchOpened)
  }

  function toggleLanguage() {
    setSiteLang(siteLang === defaultLanguage ? 'en' : 'de')
  }

  function loadNewRandomArtworks(event) {
    event.preventDefault()
    loadRandomArtworks()
  }

  function getView(view) {
    switch (view) {
      case 'list':
        return <ListView />
      case '3d':
        return <Scene />
      case 'artists':
        return <ArtistListView />
      default:
        return <WelcomePage setView={setView} setSearchOpened={setSearchOpened} />
    }
  }

  return (
    <LanguageContext.Provider value={siteLang}>
      <TranslationStringsContext.Provider value={getTranslatedItems(siteLang)}>
        <Helmet
            htmlAttributes={{
              lang: siteLang
            }}
            title={getTranslatedItems(siteLang).common.collection}
            titleTemplate={`%s - Kunstmuseum Wolfsburg`}
        />
        <SiteHeader logo={logo} transparent={siteHeaderTransparent} logoOnClick={returnToRoot} iconMode={true}>
          {(view !== 'welcome' || isArtworkOrArtistPage()) && <>
            <MenuItem onClick={returnToHome}><Home /></MenuItem>
            {(view === '3d' && !isArtworkOrArtistPage()) && <MenuItem onClick={loadNewRandomArtworks}><Randomize /></MenuItem>}
          </>}
          {view && !isArtworkOrArtistPage() && <MenuItem onClick={toggleLanguage}><img className="flag" src={siteLang === defaultLanguage ? flag_en : flag_de} /></MenuItem>}
          <MenuItem onClick={toggleSearch}><Search /></MenuItem>
        </SiteHeader>
        <FloatingNavigation>
          <MenuItem link="https://www.kunstmuseum.de" target="_blank">Kunstmuseum</MenuItem>
          <MenuItem link="https://shop.kunstmuseum.de" target="_blank">Shop</MenuItem>
          <MenuItem link="https://freundeskreis.kunstmuseum.de" target="_blank">Freundeskreis</MenuItem>
          <MenuItem link="https://www.kunstmuseum.de/curatorials/" target="_blank">Curatorials</MenuItem>
          <MenuItem link="https://guide.kunstmuseum.de" target="_blank">Audioguides</MenuItem>
          <MenuItem link="https://studiodigital.kunstmuseum.de" target="_blank">Studio Digital</MenuItem>
        </FloatingNavigation>
        <CookieConsent
          buttonText="OK"
          style={{background: 'rgba(255,255,255,0.99)', color: '#000', fontSize: '15px'}}
          buttonStyle={{background: '#000', color: '#fff', fontSize: '15px', margin: '0px'}}
          contentStyle={{margin: '0px'}}
        >
          {getTranslatedItems(siteLang).cookie_notice.text}&nbsp;
          <a className="button button-small" href="https://www.kunstmuseum.de/datenschutz" target="_blank">{getTranslatedItems(siteLang).cookie_notice.privacy_policy}</a>
        </CookieConsent>
        {searchOpened && <SearchOverlay closeSearchFunc={() => setSearchOpened(false)} />}
        <Route path="/artwork/:artworkSlug/" component={ArtworkDetailRoute} />
        <Route path="/artist/:artistSlug/" component={ArtistDetailRoute} />
        {getView(view)}
      </TranslationStringsContext.Provider>
    </LanguageContext.Provider>
  )
}

const App = () => <Router><Layout /></Router>

export default App
