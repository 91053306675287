import React, { useContext } from 'react'
import { ThreeD, List, Search } from 'kunstmuseum-icons'
import { useQuery } from '@tanstack/react-query'
import { Button } from 'kunstmuseum-components'
import { LanguageContext, TranslationStringsContext, getCollectionPublication } from './Data'
import Footer from './components/Footer'
import cover_sammlungskatalog from './media/cover_sammlungskatalog_deutsch.jpg'

const WelcomePage = (props) => {
    const currentLanguage = useContext(LanguageContext)
    const translationStrings = useContext(TranslationStringsContext)

    return (
        <div className="welcome-page">
            <h1>{translationStrings.common.collection}</h1>
            <div className="content">
                {currentLanguage === 'de' ?
                    <div>
                        <p>
                            Minimal Art, Concept Art und Arte Povera markieren den zeitlichen Beginn der mittlerweile mehr als  900 Werke umfassenden Sammlung des Kunstmuseum Wolfsburg. 
                            Mit dem Start der Sammlungstätigkeit wurde als Zäsur das Jahr 1968 gewählt, das weltweit für gravierende gesellschaftliche und künstlerische Umbrüche steht. 
                        </p>
                        <p>
                            Seit 2019 zielt die Neuausrichtung der international angelegten Sammlung auf die verstärkte Einbeziehung von Künstler*innen aus dem globalen Umfeld, die sich im Rahmen ihrer Arbeiten vor allem mit unterschiedlichsten Themenkomplexen und gesellschaftlichen Herausforderungen der Gegenwart auseinandersetzen. Grundsätzlich wird weiterhin die Absicht verfolgt, sich auf wesentliche künstlerische Positionen zu beschränken und diese insbesondere durch Werkgruppen, Serien oder anhand eines repräsentativen Querschnitts durch das künstlerische Oeuvre anschaulich werden zu lassen. Den Medien Fotografie, Film bzw. Video sowie Installationen kommen seit Anbeginn eine besondere Bedeutung zu und werden auch weiterhin als zentrale Formen der Gegenwartskultur bei der Sammlungstätigkeit bevorzugt berücksichtigt. 
                            Digitale Formate spielen hierbei eine zunehmend bedeutsame Rolle.
                        </p>
                        <p>
                            Die Präsentation von einzelnen Werken der Sammlung erfolgt zumeist unter thematischen Gesichtspunkten innerhalb unserer großen Sonderausstellungen. 
                            Auch werden in unregelmäßigen Abständen Themenausstellungen aus dem Sammlungskonvolut heraus konzipiert. 
                            Durch den Verzicht auf Dauerpräsentationen werden die Werke in immer neuen inhaltlichen und ästhetischen Dialogen gezeigt und somit die Lebendigkeit der Sammlung bewahrt.
                        </p>
                        <p>
                            Der Online-Katalog bietet die Möglichkeit eines umfassenden Überblicks auch über diejenigen Werke, die entweder nicht zu sehen sind oder aufgrund vielfältiger internationaler Leihanfragen vorübergehend in anderen Museen ausgestellt werden. 
                            Ausstellungs- und Detailansichten sowie Film- und Videosequenzen ergänzen die Abbildungen der Werke.
                        </p>
                        <p>
                            Über die nebenstehenden Schaltflächen besteht die Möglichkeit, im Sammlungsbestand nicht nur gezielt nach einzelnen Künstler*innen und Werken, sondern auch nach Inventarnummern beziehungsweise dem Erwerbungsjahr zu suchen. In einem virtuellen Ausstellungsraum wird zudem eine Auswahl von Werken der Sammlung in abwechselnden Zusammenstellungen präsentiert. 
                            Durch Betätigen der Enter-Taste gelangt man auch dort zu weiteren Informationen über die betreffenden Arbeiten.
                        </p>
                        <p>
                            Bitte beachten Sie, dass einige der Werke der Sammlung nicht für Kinder und Jugendliche geeignet sind. Diese Werke sind mit *** gekennzeichnet.
                        </p>
                    </div>
                :
                    <div>
                        <p>
                            Minimal Art, Conceptual Art, and Arte Povera mark the temporal beginning of the collection of the Kunstmuseum Wolfsburg, which now comprises more than 900 works. 
                            With the start of the collection’s activities, the year 1968 was chosen as a caesura, which stands for substantial social and artistic upheavals worldwide.
                        </p>
                        <p>
                            Since 2019, the restructuring of the internationally oriented collection has been aimed at the increased inclusion of artists from the global environment, the works of whom deal primarily with a wide variety of the thematic complexes and social challenges of the present. 
                            In principle, the intention continues to be to limit the collection to essential artistic positions and to represent these in particular through groups of works, series, or by means of a representative cross-section of the respective artistic oeuvre. The media of photography, film, and video, as well as installations have been of particular importance from the very beginning and will continue to be given priority in the collection’s activities as central forms of contemporary culture. 
                            Digital formats play an increasingly important role. 
                        </p>
                        <p>
                            The presentation of individual works from the collection generally takes place under thematic aspects within our comprehensive special exhibitions. 
                            Thematic exhibitions are also conceived at irregular intervals from within the collection. 
                            By refraining from permanent presentations, the works are shown in ever new dialogues in terms of content and aesthetics, thus preserving the vitality of the collection. 
                        </p>
                        <p>
                            The online catalog offers the possibility of a comprehensive overview even of those works that are either not on view or temporarily exhibited in other museums due to a variety of international loan requests. 
                            Installation views and details, as well as film and video sequences, complement the images of the works. 
                        </p>
                        <p>
                            Using the adjacent buttons, it is possible to search the collection not only for individual artists and works, but also for inventory numbers or the year of acquisition. 
                            In addition, a selection of works from the collection is presented in alternating constellations in a virtual exhibition space. 
                            There, further information about the works in question can be accessed by pressing the Enter key. 
                        </p>
                        <p>
                            Please note that some of the works in the collection are not suitable for children and young people. These works are marked with the symbol ***. 
                        </p>
                    </div>
                }
                <div>
                    <Button onClick={() => props.setSearchOpened(true)}><Search /> {translationStrings.common.search_collection}</Button>
                    <Button onClick={() => props.setView('artists')}><List /> {translationStrings.common.artists_a_z}</Button>
                    <Button onClick={() => props.setView('list')}><List /> {translationStrings.common.artworks}</Button>
                    <Button onClick={() => props.setView('3d')}><ThreeD /> {translationStrings.common.exhibition_space}</Button>
                    <StudioDigital />
                    <CollectionPublication />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default WelcomePage

const StudioDigital = () => (
    <div className="crosselling-product">
        <h2 className="h3" style={{ margin: 0 }}>Studio Digital</h2>
        <p>Studio Digital bringt die Sammlung des Kunstmuseum Wolfsburg in den Klassenraum: Mit Kreativtools, Informationen und medialen Lerninhalten werden ausgewählte Werke der Sammlung erfahrbar gemacht und die eigene Kreativität angeregt.</p>
        <a 
            className="button button-large"
            style={{ width: 'unset', textAlign: 'center' }}
            href="https://studiodigital.kunstmuseum.de/"
            target="_blank"
        >Zu Studio Digital</a>
    </div>
)

const CollectionPublication = () => {
    const siteLangContext = useContext(LanguageContext)

    const { isLoading, isError, data } = useQuery({
        queryKey: ['collectionPublication', siteLangContext],
        queryFn: () => getCollectionPublication(siteLangContext)
    })

    if (isLoading) {
        return (
            <div className="crosselling-product">
                <div className="placeholder cover" />
                <div className="placeholder" />
            </div>
        )
    }

    if (isError) {
        return null
    }

    return (
        <div className="crosselling-product">
            <img src={cover_sammlungskatalog} alt="Now Is The Time" />
            <p>
                Zur Jubiläumsausstellung <em>Now Is The Time</em> ist eine Sammlungs&shy;publikation mit Werken aller in der Sammlung vertretenen Künstler*innen im Hatje Cantz Verlag erschienen.
                <br /><span dangerouslySetInnerHTML={{ __html: data.price_html }} />
            </p>
            <a 
                className="button button-large"
                style={{ width: 'unset', textAlign: 'center' }}
                href={data.permalink}
                target="_blank"
            >Shop</a>
        </div>
    )
}