import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
import { Search } from 'kunstmuseum-icons'
import nProgress from 'nprogress'
import { performSearch, LanguageContext, TranslationStringsContext } from './Data'

export default function SearchOverlay(props) {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const siteLangContext = useContext(LanguageContext)
    const translationStrings = useContext(TranslationStringsContext)

    // Set the progress to done when the search results change.
    useEffect(() => {
        nProgress.done()
        return;
    }, [searchResults])

    useEffect(() => {
        const eventData = { searchTerm: searchTerm, setSearchResults: setSearchResults, language: siteLangContext }
        debouncedSearchInputChange(eventData)
    }, [searchTerm])

    useEffect(() => {
        setSearchTerm('')
        setSearchResults('')
    }, [siteLangContext])

    // Debounce the api search call.
    const debouncedSearchInputChange = useCallback(_.debounce(eventData => performSearch(eventData.searchTerm, eventData.setSearchResults, eventData.language), 400), [])

    // Sets the search term when the input changes.
    function searchInputChange(event) {
        setSearchTerm(event.target.value)

        // Reset search when the search term is empty.
        if (event.target.value.length === 0) {
            setSearchResults([])
            nProgress.done()
        }
    }

    // Prevent form from being submitted.
    function submitSearchForm(event) {
        event.preventDefault()
        performSearch(searchTerm, setSearchResults)
    }

    return(
        <div className="search-overlay" onClick={() => props.closeSearchFunc()}>
            <div className="content" onClick={(event) => event.stopPropagation()}>
                <form role="search" onSubmit={submitSearchForm}>
                    <label className="screen-reader-text" htmlFor="s">{translationStrings.search.search_for}</label>
                    <input type="search" name="s" placeholder={translationStrings.search.search_term} value={searchTerm} onChange={searchInputChange} />
                    <button type="submit">
                        <Search height="22" aria-hidden="true" />
                        <span className="screen-reader-text">{translationStrings.search.search}</span>
                    </button>
                </form>
                <div className="search-results">
                    {searchResults.length ? 
                        searchResults.map((searchResultItem) => <SearchResultItem key={searchResultItem.id} itemData={searchResultItem} closeSearchFunc={props.closeSearchFunc} />)
                    :
                        searchTerm && <span className="search-result">{translationStrings.search.no_results}</span>
                    }
                </div>
            </div>
        </div>
    )
}

function SearchResultItem(props) {
    const translationStrings = useContext(TranslationStringsContext)

    const slug = props.itemData.url.split('/').slice(-2).join('/')
    const content = props.itemData.title + '<span class="search-result-type">' + (props.itemData.subtype === 'artist' ? translationStrings.common.artist : translationStrings.common.artwork) + '</span>'
    
    return(
        <Link 
            to={`/${props.itemData.subtype}/${slug}`} 
            className={classNames('search-result', props.itemData.subtype)} 
            onClick={() => props.closeSearchFunc()}
            dangerouslySetInnerHTML={{__html: content}}
        />
    )
}