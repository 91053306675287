import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
//import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { Button } from 'kunstmuseum-components'
import { Close, Search, ArrowRight } from 'kunstmuseum-icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Zoom } from 'swiper'
import nProgress from 'nprogress'
import { loadArtworkDetails, useQueryParams, LanguageContext, TranslationStringsContext } from './Data'
import { newLineToBreakTag, videoLinkInHtmlToEmbed } from './Helpers'
import 'swiper/css/navigation'
import 'swiper/css/zoom'

SwiperCore.use([Navigation, Zoom])

export default function DetailOverlay(props) {
    const [artworkDetails, setArtworkDetails] = useState(null)
    const [showLongDescription, setShowLongDescription] = useState(false)
    const [lightboxOpened, setLightboxOpened] = useState(false)
    const siteLangContext = useContext(LanguageContext)
    const translationStrings = useContext(TranslationStringsContext)
    const queryParams = useQueryParams()
    const DetailOverlayRef = useRef()

    /*const { data: artworkDetails, isLoading, isSuccess } = useQuery({
        queryKey: ['artworkDetails', props.artworkId, props.artworkSlug, siteLangContext],
        queryFn: () => loadArtworkDetails(props.artworkId, props.artworkSlug, queryParams.get('lang') ? queryParams.get('lang') : siteLangContext)
    })

    if (isLoading) {
        nProgress.start()
    }

    if (isSuccess) {
        nProgress.done()
        setLightboxOpened(false)
        setShowLongDescription(false)

        if (DetailOverlayRef.current) {
            DetailOverlayRef.current.scrollTop = 0
        }
    }*/

    useEffect(() => {
        const langQueryParam = queryParams.get('lang')

        setArtworkDetails(null)
        loadArtworkDetails(props.artworkId, props.artworkSlug, setArtworkDetails, langQueryParam ? langQueryParam : siteLangContext)
    }, [props.artworkId, props.artworkSlug, siteLangContext])

    // Set the progress to done when the artwork details change.
    useEffect(() => {
        nProgress.done()
        setLightboxOpened(false)
        setShowLongDescription(false)
        DetailOverlayRef.current.scrollTop = 0
        return;
    }, [artworkDetails])

    return (
        <div className="detail-overlay" role="dialog" ref={DetailOverlayRef}>
            <h2 className="title">
                {artworkDetails ? 
                    <><span dangerouslySetInnerHTML={{ __html: artworkDetails.artist[0].firstname}}/> <span dangerouslySetInnerHTML={{ __html: artworkDetails.artist[0].lastname }} />, <em dangerouslySetInnerHTML={{ __html: artworkDetails.title.rendered }} />{artworkDetails.year != '' && `, ${artworkDetails.year}`}</> 
                : 
                    <div className="placeholder" />
                }
            </h2>
            <section
                className={
                    classNames(
                        'image',
                        artworkDetails && artworkDetails.featured_image != null && artworkDetails.featured_image.media_details.height > artworkDetails.featured_image.media_details.width ? 'portrait' : 'landscape'
                    )
                }
            >
                <div className="main-image" onClick={() => artworkDetails && setLightboxOpened(true)}>
                    {artworkDetails && artworkDetails.featured_image !== null ?
                        <>
                            <img src={('large' in artworkDetails.featured_image.media_details.sizes ? artworkDetails.featured_image.media_details.sizes.large : artworkDetails.featured_image).source_url} alt={artworkDetails.featured_image.alt_text} />
                            {artworkDetails.views.length ? 
                                <div className="lightbox-badge">+{artworkDetails.views.length}</div> 
                            : 
                                <div className="lightbox-badge"><Search /></div>
                            }
                        </>
                    :
                        <div className="placeholder" />
                    }
                </div>
                <div className="details">
                    <div>
                        {artworkDetails ? 
                            <Link className="button button-large" to={'/artist/' + artworkDetails.artist[0].post_name + '/'}>{translationStrings.artist.about_the_person}</Link> 
                        : 
                            <Button disabled>{translationStrings.artist.about_the_person}</Button>
                        }
                    </div>
                    {artworkDetails ?
                        <>
                            <div className="content">
                                <div className="details" dangerouslySetInnerHTML={{ __html: artworkDetails.content.rendered }} />
                            </div>
                        </>
                    :
                        <div className="placeholder" />
                    }
                </div>
            </section>
            {artworkDetails && artworkDetails.featured_image != null &&
                <CreditsOverlay>
                    <CreditItem 
                        image={('medium' in artworkDetails.featured_image.media_details.sizes ? artworkDetails.featured_image.media_details.sizes.medium : artworkDetails.featured_image.media_details).source_url}
                        text={artworkDetails.featured_image.media_details.image_meta.copyright}
                        photographer={artworkDetails.featured_image.media_details.image_meta.photographer}
                        imageAltText={artworkDetails.featured_image.alt_text} 
                        addCurrentYear={artworkDetails.featured_image.media_details.image_meta.add_current_year === "1"} 
                    />
                    {artworkDetails.views && artworkDetails.views.map((view, index) => 
                        <CreditItem
                            key={index}
                            image={view.guid} 
                            text={view.copyright} 
                            photographer={view.photographers[0] || view.photographer[0]}
                            addCurrentYear={view.add_current_year === "1"} 
                        />
                    )}
                </CreditsOverlay>
            }
            {(artworkDetails && artworkDetails.description) &&
                <section className="description has-gray-background">
                    <h2>Beschreibung</h2>
                    <div className="short-description" dangerouslySetInnerHTML={{ __html: videoLinkInHtmlToEmbed(newLineToBreakTag(artworkDetails.description)) }} />
                    {artworkDetails.long_description &&
                        <>
                            {showLongDescription ?
                                <div className="long-description" dangerouslySetInnerHTML={{ __html: videoLinkInHtmlToEmbed(newLineToBreakTag(artworkDetails.long_description)) }} />
                            :
                                <Button className="show-more-button" onClick={() => setShowLongDescription(true)}>{translationStrings.common.more}</Button>
                            }
                        </>
                    }
                </section>
            }
            {lightboxOpened && artworkDetails &&
                <div className="lightbox" role="dialog">
                    <button className="close-lightbox" onClick={() => setLightboxOpened(false)}>
                        <Close /> 
                        <span className="screen-reader-text">{translationStrings.common.close}</span>
                    </button>
                    <Swiper
                        navigation
                        zoom={{maxRatio: 2}}
                        grabCursor
                    >
                        <SwiperSlide zoom><img src={artworkDetails.featured_image.source_url} alt={artworkDetails.featured_image.alt_text} /></SwiperSlide>
                        {artworkDetails.views && artworkDetails.views.map((view, index) => <SwiperSlide key={index} zoom><img src={view.guid} alt={view.post_excerpt} /></SwiperSlide>)}
                    </Swiper>
            </div>
            }
        </div>
    )
}

function CreditsOverlay(props) {
    const [creditsOpened, setCreditsOpened] = useState(false)

    return (
        <>
            <button className="button-arrow open-credits-overlay" onClick={() => setCreditsOpened(true)}>
                <ArrowRight /> Credits 
            </button>
            {creditsOpened &&
                <div className="credits-overlay" role="dialog" onClick={() => setCreditsOpened(false)}>
                    <h3 className="h1">Credits</h3>
                    <div className="credits-list">{props.children}</div>
                </div>
            }
        </>
    )
}

const CreditItem = (props) => {
    const translationStrings = useContext(TranslationStringsContext)
    
    return (
        <div className="credit-item">
            <img src={props.image} alt={props.imageAltText} />
            <p>&copy; {props.text}{props.addCurrentYear && ` ${new Date().getFullYear()}`}{props.photographer && !props.text.includes('Foto:') && <>, {translationStrings.common.photo}: {props.photographer.name}</>}</p>
        </div>
    )
}