import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import React, { useRef, useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { PointerLockControls } from './PointerLockControls'
import { loadRandomArtworks, useStoredState, LanguageContext, TranslationStringsContext } from './Data'
import { isTouchDevice } from './Helpers'
import nProgress from 'nprogress'
import classNames from 'classnames'
import { ArrowUp, ArrowDown, ArrowLeft, ArrowRight, EnterKey } from 'kunstmuseum-icons'
import IntroOverlay from './IntroOverlay'

const textureLoader = new THREE.TextureLoader()
const gltfLoader = new GLTFLoader()
let scene = null
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
const renderer = new THREE.WebGLRenderer({ antialias: true })
renderer.shadowMap.type = THREE.PCFShadowMap
renderer.shadowMap.enabled = true
renderer.shadowMapSoft = true

let canvas
let controls
let prevTime
let moveVelocity

const mouse = new THREE.Vector2()
const raycaster = new THREE.Raycaster()

let artGroup = new THREE.Group()

let intersectObjects = []

let moveForward = false
let moveBackward = false
let moveLeft = false
let moveRight = false

let selectedArtworkSlug = null
let setArtworkSelectedFunc = () => null
let setIsControlEnabledFunc = () => null

/**
 * Returns whether the browser supports pointer lock.
 */
function browserSupportsPointerLock() {
    if ('pointerLockElement' in document || 'mozPointerLockElement' in document || 'webkitPointerLockElement' in document)
        return true

    return false
}

/**
 * Initialize three.js scene.
 * 
 * @param {*} sceneHolderRef The reference to the element for the canvas.
 */
function initializeThree(canvasHolderRef) {    
    scene = new THREE.Scene()
    controls = new PointerLockControls(camera)
    scene.add(controls.getObject())

    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(0x000000, 1)
    canvasHolderRef.current.appendChild(renderer.domElement)

    canvas = document.querySelector('canvas')

    // Only when pointer is locked will translation controls be allowed: controls.enabled
    moveVelocity = new THREE.Vector3()
    moveForward = false
    moveBackward = false
    moveLeft = false
    moveRight = false

    // Renderer time delta.
    prevTime = performance.now()

    // Resize if window size change!
    window.addEventListener('resize', function () {
        renderer.setSize(window.innerWidth, window.innerHeight)
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
    })

    controls.getObject().position.y = 1.6
}

/**
 * Sets up the 3d scene.
 */
function setupScene(language) {
    // set up lighting.
    const ambientWorldLight = new THREE.AmbientLight(0x2a2a2a)
    scene.add(ambientWorldLight)

    const ceilingLight1 = new THREE.RectAreaLight(0xffffff, 1, 40, 22)
    ceilingLight1.position.y = 6.95
    ceilingLight1.rotation.x = -Math.PI / 2
    scene.add(ceilingLight1)

    const light1 = new THREE.PointLight( 0xffffff, 0.1, 15 )
    light1.position.set( 0, 6, 0 )
    scene.add( light1 )
    
    const light2 = new THREE.PointLight( 0xffffff, 0.1, 15 )
    light2.position.set( 12, 6, 0 )
    scene.add( light2 )
    
    const light3 = new THREE.PointLight( 0xffffff, 0.1, 15 )
    light3.position.set( -12, 6, 0 )
    scene.add( light3 )

    const floorRepeatSize = 17.85

    // setup floor.
    const floorTexture = textureLoader.load('/img/WoodFlooring044_COL_1K_v2.jpg')
    floorTexture.wrapS = THREE.RepeatWrapping
    floorTexture.wrapT = THREE.RepeatWrapping
    floorTexture.repeat.set(floorRepeatSize, floorRepeatSize)

    const floorTextureNormal = textureLoader.load('/img/WoodFlooring044_NRM_1K.jpg')
    floorTextureNormal.wrapS = THREE.RepeatWrapping
    floorTextureNormal.wrapT = THREE.RepeatWrapping
    floorTextureNormal.repeat.set(floorRepeatSize, floorRepeatSize)

    const floorTextureRoughness = textureLoader.load('/img/WoodFlooring044_REFL_1K.jpg')
    floorTextureRoughness.wrapS = THREE.RepeatWrapping
    floorTextureRoughness.wrapT = THREE.RepeatWrapping
    floorTextureRoughness.repeat.set(floorRepeatSize, floorRepeatSize)

    // Phong is for shiny surfaces.
    const floorMaterial = new THREE.MeshStandardMaterial({ map: floorTexture, normalMap: floorTextureNormal, roughnessMap: floorTextureRoughness })
    const floor = new THREE.Mesh(new THREE.PlaneGeometry(45, 45), floorMaterial)
    floor.name = "floor"

    floor.rotation.x = Math.PI / 2
    floor.rotation.y = Math.PI
    scene.add(floor)

    // Create the walls.
    const wallGroup = new THREE.Group()
    wallGroup.name = "wallGroup"
    scene.add(wallGroup)

    const wallMaterial1 = new THREE.MeshStandardMaterial({ color: 0xffffff })
    const wallMaterial2 = new THREE.MeshStandardMaterial({ color: 0xffffff })
    const wallMaterial3 = new THREE.MeshStandardMaterial({ color: 0xffffff })
    const wallMaterial4 = new THREE.MeshStandardMaterial({ color: 0xffffff })

    // consider BufferGeometry for static objects in the future.
    const wall1 = new THREE.Mesh(new THREE.PlaneGeometry(40, 8), wallMaterial1)
    const wall2 = new THREE.Mesh(new THREE.PlaneGeometry(12, 8), wallMaterial2)
    const wall3 = new THREE.Mesh(new THREE.PlaneGeometry(12, 8), wallMaterial3)
    const wall4 = new THREE.Mesh(new THREE.PlaneGeometry(40, 8), wallMaterial4)

    wall1.position.z = -6

    wall2.position.x = -20
    wall2.rotation.y = Math.PI / 2

    wall3.position.x = 20
    wall3.rotation.y = -Math.PI / 2

    wall4.position.z = 6
    wall4.rotation.y = Math.PI

    wall1.name = "longWall1"
    wall2.name = "shortWall1"
    wall3.name = "shortWall2"
    wall4.name = "longWall2"

    wallGroup.add(wall1)
    wallGroup.add(wall2)
    wallGroup.add(wall3)
    wallGroup.add(wall4)

    wallGroup.position.y = 3

    // Set up the ceiling.
    let ceilMaterial = new THREE.MeshBasicMaterial({ color: 0xdddddd })
    let ceil = new THREE.Mesh(new THREE.PlaneGeometry(40, 12), ceilMaterial)
    ceil.name = "ceiling"
    ceil.position.y = 7
    ceil.rotation.x = Math.PI / 2

    scene.add(ceil)

    const benchMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff })

    gltfLoader.load('/models/large_bench.glb', (gltf) => {
        gltf.scene.traverse((object) => {
            if (object.isMesh) {
                object.castShadow = true
                object.material = benchMaterial
            }
        });
        const bench = gltf.scene.children[0]
        bench.name = "largeBench"
        bench.scale.set(0.75, 0.75, 0.75) // TODO: this is not realworld scale, just optically fitting

        scene.add(bench)
    })

    gltfLoader.load('/models/small_bench.glb', (gltf) => {
        gltf.scene.traverse((object) => {
            if (object.isMesh) {
                object.castShadow = true
                object.material = benchMaterial
            }
        });
        const bench1 = gltf.scene.children[0]
        bench1.name = "smallBench1"
        bench1.position.x = -12
        bench1.scale.set(0.75, 0.75, 0.75)

        scene.add(bench1)

        const bench2 = bench1.clone()
        bench2.name="smallBench2"
        bench2.position.x = 12
        bench2.scale.set(0.75, 0.75, 0.75)

        scene.add(bench2)
    })

    const testGeometry = new THREE.BoxGeometry(3, 3, 0.9)
    const testMesh = new THREE.Mesh(testGeometry, benchMaterial)
    //scene.add(testMesh)

    // Add artworks.
    artGroup.name = "artGroup"
    scene.add(artGroup)

    loadRandomArtworks(language)
}

export function setArtworks( artworksData ) {
    // Clear art group in case it is not empty.
    artGroup.clear()
    intersectObjects = []

    const artworksPerWall = Math.floor( artworksData.length / 2 )
    const artworksPositionY = 1.6
    let artworksLoadedCount = 0

    artworksData.forEach( ( artwork, index ) => {
        if ( artwork.featured_media !== 0 ) {
            const imageSizes = artworksData[index].featured_image.media_details.sizes
            const artworkImage = 'medium_large' in imageSizes ? imageSizes.medium_large : imageSizes.medium

            textureLoader.load( artworkImage.source_url, texture => {
                const imageMaterial = new THREE.MeshStandardMaterial( { map: texture  } )

                const ratioW = artwork.width / 100
                const ratioH = artwork.height / 100

                // Plane for the artwork.
                const planeGeometry = new THREE.PlaneGeometry( ratioW, ratioH )
                const planeMesh = new THREE.Mesh( planeGeometry, imageMaterial ) // Width, Height
                planeMesh.name = artworksData[ index ].slug
                planeMesh.overdraw = true

                // Determine on which wall the artwork will be displayed at.
                // -1 because index is 0 - n-1 but num of artworks is n 
                if ( index <= artworksPerWall - 1 ) {
                    const positionX = 2.5 * index - 17.5
                    const positionZ = -5.96

                    // plane.rotation.z = Math.PI/2
                    planeMesh.position.set( positionX, artworksPositionY, positionZ ) // Y and Z kept constant.
                } else {
                    const positionX = 2.5 * index - 55
                    const positionZ = 5.96

                    // plane.rotation.z = Math.PI/2
                    planeMesh.position.set( positionX, artworksPositionY, positionZ )
                    
                    // plane.position.set(65*i - 75*Math.floor(num_of_artworks/2) - 15*Math.floor(num_of_artworks/2), 48, 90)
                    planeMesh.rotation.y = Math.PI
                }

                artGroup.add( planeMesh )
                intersectObjects.push( planeMesh )

                imageMaterial.map.needsUpdate = true

                artworksLoadedCount++
                nProgress.set(artworksLoadedCount / 100)
                if (artworksLoadedCount === artworksData.length) nProgress.done()
            } )
        } else {
            console.warn(`Featured media for artwork ${artworksData[index].id} is missing.`)
        }
    } )
}

/**
 * Sets up the player movement controls.
 */
function setupKeyboardControls(history) {
    document.addEventListener("keydown", function (e) {
        if (e.keyCode === 87 || e.keyCode === 38) { // W or UP.
            moveForward = true
        }
        else if (e.keyCode === 65 || e.keyCode === 37) { // A or LEFT.
            moveLeft = true
        }
        else if (e.keyCode === 83 || e.keyCode === 40) { // S or DOWN.
            moveBackward = true
        }
        else if (e.keyCode === 68 || e.keyCode === 39) { // D or RIGHT.
            moveRight = true
        }
    })

    document.addEventListener("keyup", function (e) {
        if (e.keyCode === 87 || e.keyCode === 38) { // W or UP.
            moveForward = false
        }
        else if (e.keyCode === 65 || e.keyCode === 37) { // A or LEFT.
            moveLeft = false
        }
        else if (e.keyCode === 83 || e.keyCode === 40) { // S or DOWN.
            moveBackward = false
        }
        else if (e.keyCode === 68 || e.keyCode === 39) { // D or RIGHT.
            moveRight = false
        }
        if (e.keyCode === 13 && selectedArtworkSlug != null) { // Enter
            document.exitPointerLock()
            history.push('/artwork/' + selectedArtworkSlug + '/')
        }
    })
}

function setupTouchMovementControl(canvasHolderRef) {
    const movementControls = canvasHolderRef.current.querySelector('.movement-control')

    const movementControlForward = movementControls.querySelector('.forward')
    const movementControlBackward = movementControls.querySelector('.backward')
    const movementControlLeft = movementControls.querySelector('.left')
    const movementControlRight = movementControls.querySelector('.right')

    movementControlForward.addEventListener('touchstart', () => moveForward = true)
    movementControlForward.addEventListener('touchend', () => moveForward = false)

    movementControlBackward.addEventListener('touchstart', () => moveBackward = true)
    movementControlBackward.addEventListener('touchend', () => moveBackward = false)

    movementControlLeft.addEventListener('touchstart', () => moveLeft = true)
    movementControlLeft.addEventListener('touchend', () => moveLeft = false)

    movementControlRight.addEventListener('touchstart', () => moveRight = true)
    movementControlRight.addEventListener('touchend', () => moveRight = false)
}

/**
 * Callback for when the pointerlock state changes.
 * 
 * @param {*} event 
 */
function pointerlockChangeCallback(event) {
    if (document.pointerLockElement === canvas || document.mozPointerLockElement === canvas || document.webkitPointerLockElement === canvas) {
        // Pointer is disabled by element.
        controls.enabled = true
        setIsControlEnabledFunc(true)
        
        //start mouse move listener
        document.addEventListener("mousemove", pointerlockMovementCallback, false)

    } else {
        // Pointer is no longer disabled.
        controls.enabled = false
        setIsControlEnabledFunc(false)

        document.removeEventListener("mousemove", pointerlockMovementCallback, false)
    }
}

/**
 * Callback for when the pointerlock throws an error.
 * 
 * @param {*} event 
 */
function pointerlockErrorCallback(event) {
    alert("Pointer Lock Failed")
}

/**
 * Callback for when the pointer moves.
 * 
 * @param {*} event 
 */
function pointerlockMovementCallback(event) {
    // Now that pointer disabled, we get the movement in x and y pos of the mouse.
    let movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0
    let movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0
}

/**
 * Sets up the raycaster.
 */
function setupRaycaster(history, isTouchMode) {
    if (!isTouchMode) {
        mouse.x = 0.1
        mouse.y = 0.1

        raycaster.far = 2.5
    }

    canvas.addEventListener('touchstart', (event) => {
        mouse.x = (event.touches[0].clientX / canvas.clientWidth) * 2 - 1
        mouse.y = -(event.touches[0].clientY / canvas.clientHeight) * 2 + 1
    })

    canvas.addEventListener('touchend', () => {
        if (selectedArtworkSlug !== null) history.push('/artwork/' + selectedArtworkSlug + '/')
    })
}

/**
 * Sets up the player look around controls.
 */
function setupPlayerLookAroundControls(canvasHolderRef, isTouchMode) {
    // If pointer lock supported in browser.
    if (browserSupportsPointerLock() && !isTouchMode) {
        // Assign the API functions for pointer lock based on browser.
        canvas.requestPointerLock = canvas.requestPointerLock || canvas.mozRequestPointerLock || canvas.webkitRequestPointerLock
        //run this function to escape pointer Lock
        canvas.exitPointerLock = canvas.exitPointerLock || canvas.mozExitPointerLock || canvas.webkitExitPointerLock

        /*Order of executions:
        canvas "click" -> "pointerlockchange" -> gl.changeCallback
        -> listen to mouse movement and locked

        ESC key -> "pointerlockchange" -> gl.changeCallback -> unlocked
        now listen to when the canvas is clicked on
        */
        canvas.addEventListener("click", function () {
            if (!controls.enabled) {
                canvas.requestPointerLock()
            } else {
                document.exitPointerLock()
            }
        })

        //pointer lock state change listener
        document.addEventListener('pointerlockchange', pointerlockChangeCallback, false)
        document.addEventListener('mozpointerlockchange', pointerlockChangeCallback, false)
        document.addEventListener('webkitpointerlockchange', pointerlockChangeCallback, false)

        document.addEventListener('pointerlockerror', pointerlockErrorCallback, false)
        document.addEventListener('mozpointerlockerror', pointerlockErrorCallback, false)
        document.addEventListener('webkitpointerlockerror', pointerlockErrorCallback, false)


    } else if (!isTouchDevice) {
        alert("Your browser does not support the Pointer Lock API")
    }

    if (isTouchMode) {
        const rotationControl = canvasHolderRef.current.querySelector('.rotation-control')

        const rotationControlRegion = rotationControl.querySelector('.region')
        const rotationControlHandle = rotationControl.querySelector('.handle')
        
        let rotationControlTouching = false
        let eventRepeatTimeout = null

        const rotationControlLeftPosition = rotationControl.offsetLeft
        const rotationControlTopPosition = rotationControl.offsetTop

        const rotationControlRegionWidth = rotationControlRegion.offsetWidth
        const rotationControlRegionHeight = rotationControlRegion.offsetHeight

        const rotationControlHandleWidth = rotationControlHandle.offsetWidth
        const rotationControlHandleHeight = rotationControlHandle.offsetHeight
        const rotationControlHandleRadius = rotationControlHandleWidth / 2

        const rotationControlRegionRadius = rotationControlRegionWidth / 2

        function resetRotationControl() {
            rotationControlTouching = false
            rotationControlHandle.style.opacity = '0.4'
            const rotationControlHandleResetPosition = (rotationControlRegionRadius / 2) + rotationControlHandleRadius / 2
            rotationControlHandle.style.left = rotationControlHandleResetPosition + 'px'
            rotationControlHandle.style.top = rotationControlHandleResetPosition + 'px'
        }

        resetRotationControl()

        rotationControlRegion.addEventListener('touchstart', (event) => {
            rotationControlTouching = true
            rotationControlHandle.style.opacity = '1.0'
            updateRotationControlHandle(event.touches[0].clientX, event.touches[0].clientY)
        })

        rotationControlRegion.addEventListener('touchmove', (event) => updateRotationControlHandle(event.touches[0].clientX, event.touches[0].clientY))
        rotationControlRegion.addEventListener('touchend', resetRotationControl)
        rotationControlRegion.addEventListener('touchcancel', resetRotationControl)

        function updateRotationControlHandle(clientX, clientY) {
            let newLeftPosition = (clientX - rotationControlLeftPosition)
            let newTopPosition = (clientY - rotationControlTopPosition)

            // If the handle reaches the pad boundaries.
            const distance = Math.pow(rotationControlRegionRadius - newLeftPosition, 2) + Math.pow(rotationControlRegionRadius - newTopPosition, 2)

            if (distance > Math.pow(rotationControlRegionRadius, 2)) {
                const angle = Math.atan2((newTopPosition - rotationControlRegionRadius), (newLeftPosition - rotationControlRegionRadius))
                newLeftPosition = (Math.cos(angle) * rotationControlRegionRadius) + rotationControlRegionRadius
                newTopPosition = (Math.sin(angle) * rotationControlRegionRadius) + rotationControlRegionRadius
            }

            newLeftPosition = Math.round(newLeftPosition * 10) / 10
            newTopPosition = Math.round(newTopPosition * 10) / 10
            
            rotationControlHandle.style.left = newLeftPosition - rotationControlHandleRadius + 'px'
            rotationControlHandle.style.top = newTopPosition - rotationControlHandleRadius + 'px'

            // Providing event and data for handling camera movement.
            let deltaX = rotationControlRegionRadius - parseInt(newLeftPosition)
            let deltaY = rotationControlRegionRadius - parseInt(newTopPosition)

            // Normalize x and y between -2 to 2 range.
            deltaX = -2 + (2+2) * (deltaX - (-rotationControlRegionRadius)) / (rotationControlRegionRadius - (-rotationControlRegionRadius))
            deltaY = -2 + (2+2) * (deltaY - (-rotationControlRegionRadius)) / (rotationControlRegionRadius - (-rotationControlRegionRadius))
            deltaX = Math.round(deltaX * 10) / 10
            deltaY = Math.round(deltaY * 10) / 10

            // Invert x and y
            deltaX = deltaX > 0.0 ? -deltaX : Math.abs(deltaX)
            deltaY = deltaY > 0.0 ? -deltaY : Math.abs(deltaY)
            
            setRotation(deltaX, deltaY, 0)
        }

        function setRotation(deltaX, deltaY, middle) {
            if (!rotationControlTouching) {
                clearTimeout(eventRepeatTimeout)
                return
            }

            clearTimeout(eventRepeatTimeout)
            eventRepeatTimeout = setTimeout(() => setRotation(deltaX, deltaY, middle), 5)

            const calculatedRotation = calculateCameraRotation(deltaX, deltaY)

            if (calculatedRotation.rotationX !== null)
                controls.getObject().children[0].rotation.x = calculatedRotation.rotationX

            if (calculatedRotation.rotationY !== null)
                controls.getObject().rotation.y = calculatedRotation.rotationY
        }
    }
}

/**
 * Calculate the camera rotation.
 * 
 * @param {*} deltaX 
 * @param {*} deltaY 
 * @param {*} factor 
 */
function calculateCameraRotation(deltaX, deltaY, factor) {
    factor = factor ? factor : 0.003
    const maxPitch = 55
    let rotationY = controls.getObject().rotation.y - (deltaX * factor)
    let rotationX = controls.getObject().children[0].rotation.x - (deltaY * factor)
    rotationX = Math.max(-maxPitch, Math.min(maxPitch, rotationX))

    return {
        rotationX: rotationX,
        rotationY: rotationY
    }
}

/**
 * Gets called for each animation frame.
 */
function render() {
    requestAnimationFrame(render)

    if (controls.enabled === true || isTouchDevice()) {
        let currentTime = performance.now() //returns time in milliseconds
        // Accurate to the thousandth of a millisecond
        // Want to get the most accurate and smallest change in time
        let delta = (currentTime - prevTime) / 1000

        //controls.update(delta)

        // There's a constant deceleration that needs to be applied
        // Only when the object is currently in motion
        moveVelocity.x -= moveVelocity.x * 10.0 * delta
        // For now.
        moveVelocity.y -= 9.8 * 7.0 * delta // m/s^2 * kg * delta Time
        moveVelocity.z -= moveVelocity.z * 10.0 * delta

        // Need to apply velocity when keys are being pressed.
        if (moveForward) {
            moveVelocity.z -= 38.0 * delta
        }
        if (moveBackward) {
            moveVelocity.z += 38.0 * delta
        }
        if (moveLeft) {
            moveVelocity.x -= 38.0 * delta
        }
        if (moveRight) {
            moveVelocity.x += 38.0 * delta
        }
        
        controls.getObject().translateX(moveVelocity.x * delta)
        controls.getObject().translateY(moveVelocity.y * delta)
        controls.getObject().translateZ(moveVelocity.z * delta)

        if (controls.getObject().position.y < 1.6) {
            moveVelocity.y = 0

            controls.getObject().position.y = 1.6
        }

        prevTime = currentTime
    }

    // Set raycaster from camera.
    raycaster.setFromCamera(mouse, camera)

    // Calculate objects interesting ray.
    let intersects = raycaster.intersectObjects(intersectObjects)
    if (intersects.length !== 0) {
        //intersects[0].object.material.color.set(0xaaeeee)
        //console.log(intersects[0].distance)
        selectedArtworkSlug = intersects[0].object.name
        setArtworkSelectedFunc(true)
    } else {
        selectedArtworkSlug = null
        setArtworkSelectedFunc(false)
    }

    renderer.render(scene, camera)
}

/**
 * Initializes everything mandatory for the 3d scene.
 * 
 * @param {*} canvasHolderRef The reference to the element for the canvas.
 */
function setup(canvasHolderRef, history, isTouchMode, language) {
    initializeThree(canvasHolderRef)
    setupPlayerLookAroundControls(canvasHolderRef, isTouchMode)
    setupKeyboardControls(history)
    if (isTouchMode) setupTouchMovementControl(canvasHolderRef)
    setupScene(language)
    setupRaycaster(history, isTouchMode)
    render()
}

export default function Scene(props) {
    const canvasHolderRef = useRef()
    const history = useHistory()
    const [introSeen, setIntroSeen] = useStoredState(false, 'introSeen')
    const [isTouchMode] = useState(isTouchDevice())
    const [isControlEnabled, setIsControlEnabled] = useState(false)
    const [artworkSelected, setArtworkSelected] = useState(false)
    const siteLangContext = useContext(LanguageContext)
    const translationStrings = useContext(TranslationStringsContext)
    setArtworkSelectedFunc = setArtworkSelected
    setIsControlEnabledFunc = setIsControlEnabled

    useEffect(() => setup(canvasHolderRef, history, isTouchMode, siteLangContext), [])

    return(
        <div className="scene-holder" ref={canvasHolderRef}>
            {introSeen === false && <IntroOverlay setIntroSeenFunc={setIntroSeen} />}
            {isTouchMode ?
                <>
                    <div className="movement-control">
                        <div className="forward">
                            <ArrowUp />
                        </div>
                        <div className="backward">
                            <ArrowDown />
                        </div>
                        <div className="left">
                            <ArrowLeft />
                        </div>
                        <div className="right">
                            <ArrowRight />
                        </div>
                    </div>
                    <div className="rotation-control">
                        <div className="region">
                            <div className="handle" />
                        </div>
                    </div>
                </>
            :
                <>
                    <div className="keyboard-hints">
                        {isControlEnabled &&
                            <KeyboardHint title={translationStrings.space.controls.exit}>
                                ESC
                            </KeyboardHint>
                        }
                        <KeyboardHint title={translationStrings.space.controls.refresh}>
                            R
                        </KeyboardHint>
                        {artworkSelected &&
                            <KeyboardHint title={translationStrings.space.controls.open}>
                                <EnterKey />
                            </KeyboardHint>
                        }
                    </div>
                    <div className={classNames('selection-indicator', artworkSelected && 'enabled')} />
                </>
            }
        </div>
    )
}

const KeyboardHint = (props) => (
    <div className="hint">
        <span>{props.title}</span>
        <div className="icon">{props.children}</div>
    </div>
)