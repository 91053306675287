import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import nProgress from 'nprogress'
import { loadArtistList, LanguageContext } from './Data'

let currentLetter = null

export default function ListView() {
    const siteLangContext = useContext(LanguageContext)

    const { data, isLoading, isSuccess } = useQuery({
        queryKey: ['artistList', siteLangContext],
        queryFn: () => loadArtistList(siteLangContext)
    })

    if (isLoading) {
        nProgress.start()
    }

    if (isSuccess) {
        nProgress.done()
    }

    return (
        <div className="list-view">
            {data && data.map((artist) => <ArtistListItem artist={artist} key={artist.id} />)}
        </div>
    )
}

function ArtistListItem(props) {
    const artist = props.artist
    let showLetterHeading = false

    if (artist.lastname.charAt(0) !== currentLetter) {
        currentLetter = artist.lastname.charAt(0)
        showLetterHeading = true
    }

    return (
        <div className="artist-list-item">
            {showLetterHeading &&<h1>{currentLetter}</h1>}

            <Link to={'/artist/' + artist.slug + '/'}>
                <h3 className="h4" dangerouslySetInnerHTML={{ __html: artist.firstname + ' ' +  artist.lastname}} />
            </Link>
        </div>
    )
}